import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=16293040&scoped=true&"
import script from "./ResetPassword.vue?vue&type=script&lang=js&"
export * from "./ResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./ResetPassword.vue?vue&type=style&index=0&id=16293040&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16293040",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAppBar,VBtn,VCard,VCol,VContainer,VFooter,VForm,VMain,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1532393991/src/playtient-web-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16293040')) {
      api.createRecord('16293040', component.options)
    } else {
      api.reload('16293040', component.options)
    }
    module.hot.accept("./ResetPassword.vue?vue&type=template&id=16293040&scoped=true&", function () {
      api.rerender('16293040', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ResetPassword.vue"
export default component.exports