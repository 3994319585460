import { render, staticRenderFns } from "./EmailVerified.vue?vue&type=template&id=5547d8ba&scoped=true&"
import script from "./EmailVerified.vue?vue&type=script&lang=js&"
export * from "./EmailVerified.vue?vue&type=script&lang=js&"
import style0 from "./EmailVerified.vue?vue&type=style&index=0&id=5547d8ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5547d8ba",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1532393991/src/playtient-web-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5547d8ba')) {
      api.createRecord('5547d8ba', component.options)
    } else {
      api.reload('5547d8ba', component.options)
    }
    module.hot.accept("./EmailVerified.vue?vue&type=template&id=5547d8ba&scoped=true&", function () {
      api.rerender('5547d8ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/EmailVerified.vue"
export default component.exports