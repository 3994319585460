var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3 mt-12" }, [
                _vm.verifySuccess && !_vm.isMobile()
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("emailVerified.successTextHtml")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.verifySuccess && _vm.isMobile()
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("emailVerified.mobileSuccessText")
                        ),
                      },
                    })
                  : _vm._e(),
                _vm.verifySuccess === false
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("emailVerified.failedTextHtml")
                        ),
                      },
                    })
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "space-around",
                  },
                },
                [
                  _vm.verifySuccess
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-center initial",
                          staticStyle: {
                            width: "400px",
                            margin: "auto",
                            "min-width": "280px",
                          },
                          attrs: {
                            disabled: _vm.requestInProgress,
                            "x-large": "",
                            depressed: "",
                            rounded: "",
                            color: "secondary",
                          },
                          on: { click: _vm.login },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("emailVerified.loginButtonText"))
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.verifySuccess === false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "text-center initial truncated",
                          staticStyle: {
                            width: "300px",
                            margin: "auto",
                            "min-width": "280px",
                          },
                          attrs: {
                            disabled: _vm.requestInProgress,
                            "x-large": "",
                            depressed: "",
                            rounded: "",
                            color: "secondary",
                          },
                          on: { click: _vm.createNewAccount },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("emailVerified.signupButtonText"))
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }